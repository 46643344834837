import {
   ChangeDetectionStrategy,
   Component,
   computed,
   inject,
   input,
   output,
   signal,
} from "@angular/core";
import { takeUntilDestroyed, toObservable } from "@angular/core/rxjs-interop";
import { PanelComponent } from "@limblecmms/lim-ui";
import { filter, firstValueFrom } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { DataViewerStateService } from "src/app/shared/data-viewer";
import type { Column } from "src/app/shared/data-viewer/column-builder";
import type {
   ListResponse,
   RequestFilter,
   RequestOptions,
} from "src/app/shared/services/flannel-api-service";
import type { LimbleMap } from "src/app/shared/utils/limbleMap";
import { TasksApiService } from "src/app/tasks/components/shared";
import type { TaskDataViewerViewModel } from "src/app/tasks/components/shared/components/tasks-data-viewer/task-data-viewer.model";
import { TasksDataViewerComponent } from "src/app/tasks/components/shared/components/tasks-data-viewer/tasks-data-viewer.component";
import { TaskColumnDefinitionsFactoryService } from "src/app/tasks/components/shared/services/task-column-definitions-factory/task-column-definitions-factory.service";
import { TaskViewModelFactoryService } from "src/app/tasks/components/shared/services/task-view-model-factory/task-view-model-factory.service";
import type { TaskEntity } from "src/app/tasks/components/shared/services/tasks-api/task-api.models";
import { TasksFacadeService } from "src/app/tasks/components/shared/services/tasks-facade/tasks-facade.service";
import { TaskSearchHintService } from "src/app/tasks/search-hint/task-search-hint.service";

@Component({
   selector: "global-search-task-list",
   templateUrl: "./global-search-task-list.component.html",
   styleUrls: ["./global-search-task-list.component.scss"],
   imports: [PanelComponent, TasksDataViewerComponent],
   providers: [TasksFacadeService, DataViewerStateService],
   changeDetection: ChangeDetectionStrategy.Default,
})
export class GlobalSearchTaskListComponent {
   private readonly tasksApiService = inject(TasksApiService);

   public searchInput = input.required<string>();
   public showTasksChecked = input.required<boolean>();
   public readonly requestState = output<{
      isLoadingResults: boolean; // for tracking initial query results
      isEmpty: boolean;
      hasError: boolean;
   }>();
   private readonly searchInput$ = toObservable(this.searchInput).pipe(
      filter((searchInput) => searchInput.length > 0),
   );

   public loadingTasks = signal(false); // for internal tracking of any load status on tasks - including pagination
   public viewModel = signal<
      | {
           tasks: TaskDataViewerViewModel[];
           total: number;
           searchHints: LimbleMap<number, string>;
           columns: Column[];
        }
      | undefined
   >(undefined);

   private readonly columns: Column[];

   private readonly dataViewerState = inject(DataViewerStateService);
   private readonly taskViewModelFactoryService = inject(TaskViewModelFactoryService);
   private readonly taskColumnDefinitionsFactoryService = inject(
      TaskColumnDefinitionsFactoryService,
   );
   private readonly taskSearchHintService = inject(TaskSearchHintService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.columns =
         this.taskColumnDefinitionsFactoryService.getGlobalSearchTaskListColumns();

      this.dataViewerState.setSort("checklistName");
      this.searchInput$
         .pipe(
            takeUntilDestroyed(),
            filter((searchInput) => searchInput.length > 0),
         )
         .subscribe((searchInput) => {
            this.dataViewerState.setSearch(searchInput);
            this.viewModel.set(undefined); // we need to reset the viewModel on new search query
            // we're emitting the loading results to parent only on searchInput change, since we just need to communicate to parent on initial search query load/results
            if (this.showTasksChecked()) {
               this.requestState.emit({
                  isLoadingResults: true,
                  isEmpty: false,
                  hasError: false,
               });
            }
         });

      this.dataViewerState.requestOptions$
         .pipe(
            takeUntilDestroyed(),
            filter(
               (requestOptions) =>
                  Boolean(requestOptions.search) && this.showTasksChecked(),
            ),
         )
         .subscribe((requestOptions) => {
            this.loadingTasks.set(true);
            this.getTaskResults(requestOptions)
               .then((response) => {
                  this.viewModel.set(this.buildViewModel(response));
                  this.loadingTasks.set(false);
                  this.requestState.emit({
                     isLoadingResults: false,
                     isEmpty: response ? response.total === 0 : false,
                     hasError: false,
                  });
               })
               .catch((error: unknown) => {
                  console.error(error);
                  this.requestState.emit({
                     isLoadingResults: false,
                     isEmpty: false,
                     hasError: true,
                  });
                  this.loadingTasks.set(false);
               });
         });
   }

   private async getTaskResults(
      requestOptions: Partial<RequestOptions<RequestFilter>>,
   ): Promise<Partial<ListResponse<TaskEntity>> | null> {
      if (
         !requestOptions.search?.length ||
         !requestOptions.sort ||
         !requestOptions.pagination
      ) {
         return Promise.resolve(null);
      }

      return firstValueFrom(
         this.tasksApiService.getList({
            sort: requestOptions.sort,
            pagination: requestOptions.pagination,
            params: {
               search: requestOptions.search,
               columns: "comments,commentsDetails,completedBy,completionColor,assets",
               includeDeletedAssets: true,
            },
         }),
      );
   }

   private buildViewModel(response: Partial<ListResponse<TaskEntity>> | null): {
      tasks: TaskDataViewerViewModel[];
      total: number;
      searchHints: LimbleMap<number, string>;
      columns: Column[];
   } {
      const taskEntities = response?.data ?? [];
      const tasksViewModel: TaskDataViewerViewModel[] = taskEntities.map((taskEntity) =>
         this.taskViewModelFactoryService.getTaskDataViewerViewModel(taskEntity),
      );
      const searchHints = this.taskSearchHintService.getSearchHints(
         "checklistID",
         taskEntities,
         this.searchInput() ?? "",
      );

      return {
         tasks: tasksViewModel,
         total: response?.total ?? 0,
         searchHints,
         columns: this.columns,
      };
   }
}
