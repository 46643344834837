import { inject, Component, computed } from "@angular/core";
import { AlertComponent, IconComponent, ModalService } from "@limblecmms/lim-ui";
import { ManageLang } from "src/app/languages/services/manageLang";
import { CustomizeSmtpSettings } from "src/app/settings/components/customizeSmtpSettingsModal/customizeSmtpSettings.modal.component";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageUtil } from "src/app/shared/services/manageUtil";
import { ParamsService } from "src/app/shared/services/params.service";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "failed-email-banner",
   templateUrl: "./failedEmailBanner.element.component.html",
   styleUrls: ["../banner-styles.scss"],
   imports: [IconComponent, AlertComponent],
})
export class FailedEmailBanner {
   public readonly isSuperUser: boolean;
   private readonly currentUser;
   protected bannerMessage: string = "";
   protected smallDesktopBannerMessage: string = "";

   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageUtil = inject(ManageUtil);
   private readonly alertService = inject(AlertService);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.currentUser = this.manageUser.getCurrentUser();
      this.isSuperUser = this.manageUtil.checkIfSuperUser(this.currentUser);
      this.createBannerMessage();
   }

   public openSmtpSettingsModal() {
      const instance = this.modalService.open(CustomizeSmtpSettings);

      this.paramsService.params = {
         modalInstance: instance,
      };
   }

   protected createBannerMessage() {
      if (this.lang) {
         if (this.isSuperUser) {
            this.smallDesktopBannerMessage = `${this.lang().FailedEmailAlertSuperUser} ${this.lang().SmtpSettings}`;
            this.bannerMessage = this.lang().FailedEmailAlertSuperUser;
            return;
         }
         this.smallDesktopBannerMessage = this.lang().FailedEmailAlert;
         this.bannerMessage = this.lang().FailedEmailAlert;
      }
   }

   protected showMessage() {
      this.alertService.addAlert(
         this.smallDesktopBannerMessage,
         "danger",
         Infinity,
         this.lang().Critical,
      );
   }
}
