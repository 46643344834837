import { inject } from "@angular/core";
import {
   Router,
   type ActivatedRouteSnapshot,
   type RouterStateSnapshot,
} from "@angular/router";
import { WorkRequestPermissionsService } from "src/app/tasks/services/work-request-perms/work-request-permissions.service";

export function workRequestMatchGuard(
   route: ActivatedRouteSnapshot,
   state: RouterStateSnapshot,
   redirectRoute: string,
) {
   const workRequestPermissionsService = inject(WorkRequestPermissionsService);
   const router = inject(Router);
   const canActivate =
      workRequestPermissionsService.hasRequireWorkRequestReview() &&
      workRequestPermissionsService.hasDeclineWorkRequestPermissions();

   // TODO: Add logic to check if user has any reviews that need the page, even if reviews are not required

   if (canActivate) {
      return true;
   }

   const { data } = route;
   const newUrl = state.url.replace(data.routeName, redirectRoute);
   return router.createUrlTree([newUrl]);
}
