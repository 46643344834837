import { computed, Injectable } from "@angular/core";

@Injectable({
   providedIn: "root",
})
export class WorkRequestPermissionsService {
   public readonly hasRequireWorkRequestReview = computed(() => {
      // TODO: Add logic to determine if the feature is turned on in settings
      return true;
   });

   public readonly hasDeclineWorkRequestPermissions = computed(() => {
      // TODO: Add logic to determine if the user has the necessary permissions
      return true;
   });
}
