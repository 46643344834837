import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, HostBinding, Input, computed, signal } from "@angular/core";
import { Router } from "@angular/router";
import {
   IconComponent,
   ModalService,
   LoadingAnimationComponent,
   PopoverDirective,
   PopoverService,
   ScrollContainerComponent,
   SearchBoxComponent,
   TooltipDirective,
} from "@limblecmms/lim-ui";
import { Subject, distinctUntilChanged, filter, takeUntil, tap } from "rxjs";
import { ManageAsset } from "src/app/assets/services/manageAsset";
import { ManageLang } from "src/app/languages/services/manageLang";
import { PushNotificationService } from "src/app/mobile/push-notifications/push-notification.service";
import { Confirm } from "src/app/shared/components/global/confrimModal/confirm.modal.component";
import { BetterDatePipe } from "src/app/shared/pipes/betterDate.pipe";
import { NotificationIconPipe } from "src/app/shared/pipes/notificationIcon.pipe";
import { NotificationTitlePipe } from "src/app/shared/pipes/notificationTitle.pipe";
import { AlertService } from "src/app/shared/services/alert.service";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ParamsService } from "src/app/shared/services/params.service";
import { RefreshService } from "src/app/shared/services/refresh.service";
import { NotificationHistoryType } from "src/app/shared/types/general.types";
import { ManageTask } from "src/app/tasks/services/manageTask";
import { ManageLogin } from "src/app/users/services/manageLogin";
import { ManageUser } from "src/app/users/services/manageUser";

enum NotificationsView {
   All = 0,
   Unread = 1,
}

@Component({
   selector: "notifications-popover",
   templateUrl: "./notifications-popover.element.component.html",
   styleUrls: ["./notifications-popover.element.component.scss"],
   imports: [
      TooltipDirective,
      PopoverDirective,
      IconComponent,
      NgClass,
      SearchBoxComponent,
      ScrollContainerComponent,
      BetterDatePipe,
      NotificationIconPipe,
      NotificationTitlePipe,
      LoadingAnimationComponent,
   ],
})
export class NotificationsPopover implements OnInit, OnDestroy {
   @Input() public currentUser;
   @Input() public position: any = "bottom right";

   @HostBinding("class") public readonly classes = "scroll-height-inheritance";

   protected title;
   protected message;
   protected notifications;
   protected notificationsCount = signal(0);
   protected notificationsView: NotificationsView = NotificationsView.All;
   protected searchBarNotifications: string = "";
   protected loading = false;

   private readonly loginWatchVarSub;

   private readonly onTaskIDUpdate$ = new Subject<Set<number>>();
   private readonly notificationTaskIDs = new Set<number>();
   private readonly endSubscriptions$ = new Subject();

   private readonly router = inject(Router);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLogin = inject(ManageLogin);
   private readonly manageFilters = inject(ManageFilters);
   private readonly manageTask = inject(ManageTask);
   private readonly manageAsset = inject(ManageAsset);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly alertService = inject(AlertService);
   private readonly popoverService = inject(PopoverService);
   private readonly refreshService = inject(RefreshService);
   private readonly manageLang = inject(ManageLang);
   private readonly pushNotificationService = inject(PushNotificationService);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.loginWatchVarSub = this.manageUser.currentUserChanges$
         .pipe(
            filter((currentUser) => currentUser !== undefined),
            tap((currentUser) => {
               const parsedNotifications = this.parseNotifications(currentUser);
               this.notificationTaskIDs.clear();
               parsedNotifications
                  ?.filter(
                     (notification) =>
                        notification.checklistID && notification.checklistID > 0,
                  )
                  .forEach((notification) =>
                     this.notificationTaskIDs.add(notification.checklistID),
                  );
               this.onTaskIDUpdate$.next(this.notificationTaskIDs);
            }),
         )
         .subscribe((currentUser) => {
            this.currentUser = currentUser;
            this.setNotifications();
         });
   }

   public ngOnInit() {
      this.onTaskIDUpdate$
         .pipe(
            takeUntil(this.endSubscriptions$),
            filter((taskIDs) => taskIDs.size > 0),
            distinctUntilChanged(),
         )
         .subscribe((taskIDs: Set<number>) => {
            this.loading = true;
            this.manageTask.fetchNotes({ checklistIDs: [...taskIDs] }).then(() => {
               this.loading = false;
            });
         });
   }

   public ngOnDestroy() {
      this.loginWatchVarSub.unsubscribe();
      this.endSubscriptions$.next(true);
      this.endSubscriptions$.complete();
   }

   protected refreshPage(): void {
      this.refreshService.refreshData();
   }

   public setNotifications() {
      if (this.currentUser === undefined || this.currentUser === "none") {
         return;
      }

      this.notifications = this.parseNotifications(this.currentUser);

      const unreadCount =
         this.notifications?.reduce((count, notification) => {
            notification.chkHint = `#${notification.checklistID}`;
            return notification.visited === 0 ? count + 1 : count;
         }, 0) ?? 0;
      this.updateNotificationCount(unreadCount);
   }

   public onNotificationsPopoverClose = (): void => {
      this.searchBarNotifications = "";
      this.updateNotificationsSearch();
   };

   public toggleNotificationsView() {
      if (this.notificationsView === NotificationsView.All) {
         this.notificationsView = NotificationsView.Unread;
      } else {
         this.notificationsView = NotificationsView.All;
      }
      this.setNotifications();
   }

   public updateNotificationsSearch() {
      if (this.searchBarNotifications !== undefined) {
         this.setNotifications();
      }
   }

   public close() {
      this.popoverService.destroyAllPopoverComponents();
   }

   public viewNotification(notificationToView) {
      if (notificationToView.type === NotificationHistoryType.PRICING) {
         window.open("https://limblecmms.com/pricing/", "_blank");
         return;
      }
      const historyID = notificationToView.historyID;
      let foundNotification: any = false;
      for (const key in this.currentUser.notifications) {
         if (this.currentUser.notifications[key].historyID == historyID) {
            foundNotification = this.currentUser.notifications[key];
         }
      }
      if (!foundNotification) {
         return;
      }

      this.manageTask.markNotificationHistoryVisited(historyID).then(() => {
         for (const notification of this.currentUser.notifications) {
            if (notification.historyID == historyID) {
               notification.visited = true;
            }
            this.setNotifications();
         }

         if (
            foundNotification.type === NotificationHistoryType.DASHBOARD &&
            foundNotification.targetID > 0
         ) {
            this.popoverService.destroyAllPopoverComponents();

            this.router.navigateByUrl(`customDashboard/${foundNotification.targetID}`);

            return;
         }
         if (foundNotification.checklistID > 0) {
            this.popoverService.destroyAllPopoverComponents();

            this.router.navigateByUrl(
               `/task/${foundNotification.checklistID}/${foundNotification.locationID}`,
            );

            return;
         }
         if (foundNotification.poID > 0) {
            this.popoverService.destroyAllPopoverComponents();
            this.router.navigateByUrl(`/singlePO/${foundNotification.poID}`);
            return;
         }
         if (foundNotification.prID > 0) {
            this.popoverService.destroyAllPopoverComponents();
            this.router.navigateByUrl(`/singlePR/${foundNotification.prID}`);
            return;
         }
         if (foundNotification.assetID > 0) {
            this.manageAsset.popAsset(foundNotification.assetID, {
               tabName: "checkInOut",
            });
            return;
         }
         if (foundNotification.checkOutID > 0) {
            this.popoverService.destroyAllPopoverComponents();
            this.router.navigateByUrl(`/approveCheckOut/${foundNotification.checkOutID}`);
         }
      });
   }

   public markAllNotificationsAsRead = () => {
      const instance = this.modalService.open(Confirm);

      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().MarkAllNotificationsAsReadMsg,
            title: this.lang().MarkAllNotificationsAsReadTitle,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            this.manageUser.markAllNotificationsAsRead().then((answer) => {
               if (answer.data.success == true) {
                  this.manageLogin.checkLogin().then(() => {
                     //we call check login to reload the notifications.
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  });
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
         }
      });
   };

   public deleteAllNotifications = () => {
      const instance = this.modalService.open(Confirm);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            message: this.lang().DeleteAllNotificationsMsg,
            title: this.lang().DeleteAllNotificationsTitle,
         },
      };

      instance.result.then((result) => {
         if (result == 1) {
            this.manageUser.deleteAllNotifications().then((answer) => {
               if (answer.data.success == true) {
                  this.notifications = [];
                  this.manageLogin.checkLogin().then(() => {
                     //we call check login to reload the notifications.
                     this.alertService.addAlert(this.lang().successMsg, "success", 1000);
                  });
               } else {
                  this.alertService.addAlert(this.lang().errorMsg, "danger", 6000);
               }
            });
         }
      });
   };

   private parseNotifications(currentUser): any[] | null {
      if (currentUser === undefined || currentUser === "none") {
         return null;
      }

      let tempNotificationList = currentUser.notifications;
      if (this.notificationsView === NotificationsView.Unread) {
         tempNotificationList = tempNotificationList.filter((item) => item.visited == 0);
      }

      if (this.searchBarNotifications === undefined) {
         return tempNotificationList;
      }
      return tempNotificationList?.filter((notification) =>
         this.manageFilters.searchObjValuesForString(
            notification,
            this.searchBarNotifications,
         ),
      );
   }

   private updateNotificationCount(count: number) {
      this.notificationsCount.set(count);
      this.pushNotificationService.setBadgeCount(count);
   }
}
