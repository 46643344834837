import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import {
   AlertComponent,
   IconComponent,
   SecondaryButtonComponent,
} from "@limblecmms/lim-ui";
import moment from "moment";
import { Subscription } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { AlertService } from "src/app/shared/services/alert.service";
import type { Timer } from "src/app/shared/utils/app.util";
import { TrialBannerMessageComponent } from "src/app/subscriptions/components/trialBannerElement/trialBannerMessage/trialBannerMessage.component";
import type { SubscriptionInfo } from "src/app/subscriptions/services/manageSubscription";
import { ManageSubscription } from "src/app/subscriptions/services/manageSubscription";
import type { TrialBannerOptions } from "src/app/subscriptions/services/manageTrials";
import { ManageTrials } from "src/app/subscriptions/services/manageTrials";
import { ManageUser } from "src/app/users/services/manageUser";

@Component({
   selector: "trial-banner",
   templateUrl: "./trialBanner.element.component.html",
   styleUrls: [
      "./trialBanner.element.component.scss",
      "../../../shared/components/global/banners/banner-styles.scss",
   ],
   imports: [
      IconComponent,
      TrialBannerMessageComponent,
      AlertComponent,
      SecondaryButtonComponent,
   ],
})
export class TrialBanner implements OnInit, OnDestroy {
   @Input() currentTrial;

   private langLoadedSub: Subscription | undefined;
   private trialBannerSub: Subscription | undefined;
   private timerInterval: Timer | undefined;
   public tierText: string | undefined;
   public trialTier: string | undefined;
   public robotImageSrc: string | undefined;
   public daysLeft: number = 0;
   public hoursLeft: number = 0;
   public minutesLeft: number = 0;
   public timeLeftInMS: number = 0;

   public showUpgradeButton: boolean = false;
   public showTrialingBanner: boolean = false;

   private isBasicPlanSub: Subscription = new Subscription();

   private readonly manageTrials = inject(ManageTrials);
   private readonly manageSubscription = inject(ManageSubscription);
   private readonly alertService = inject(AlertService);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   private setUserTrials() {
      const currentUser = this.manageUser.getCurrentUser();
      const customerStatus = currentUser.userInfo?.customerStatus;

      this.isBasicPlanSub = this.manageSubscription.isBasicPlan$.subscribe(
         (isBasicPlan) => {
            if (isBasicPlan) {
               this.showTrialingBanner = false;
               this.showUpgradeButton = true;

               return;
            }

            if (customerStatus === "trialing") {
               this.showTrialingBanner = true;
               this.showUpgradeButton = false;
            }
         },
      );
   }

   public ngOnInit() {
      this.setTimeLeft();
      if (this.daysLeft < 1 && this.hoursLeft > 1) {
         this.timerInterval = setInterval(() => {
            this.setTimeLeft();
         }, 60000);
      } else if (this.hoursLeft < 1 && this.timeLeftInMS > 0) {
         this.timerInterval = setInterval(() => {
            this.setTimeLeft();
         }, 1000);
      }
      this.trialBannerSub = this.manageTrials.trialBanner$.subscribe(
         (newTrial: TrialBannerOptions) => {
            if (newTrial) {
               this.currentTrial = newTrial;
               this.setDisplayInfo();
            }
         },
      );
      this.langLoadedSub = this.manageLang.langLoaded$.subscribe(() => {
         this.setDisplayInfo();
      });
   }

   private setDisplayInfo() {
      if (this.manageSubscription.getBilling()) {
         // Don't hit Chargify if we already have the data
         this.getSubscriptionInfo(this.manageSubscription.getBilling());
      } else {
         this.manageSubscription.getChargifyData().then(() => {
            this.getSubscriptionInfo(this.manageSubscription.getBilling());
         });
      }
   }

   private getSubscriptionInfo(subscriptionInfo: SubscriptionInfo) {
      const trialTier =
         this.currentTrial.trialTier ?? subscriptionInfo.chargifyPlan?.component_handle;
      const displayInfo = this.manageTrials.getTrialDisplayInfo(trialTier);
      this.robotImageSrc = displayInfo.robotImageSrc;
      this.tierText = displayInfo.tierText;
      this.trialTier = trialTier;
   }

   private setTimeLeft() {
      this.setUserTrials();
      const trialEndDateMS = Number(this.currentTrial.endOfTrial) * 1000;
      const now = new Date().getTime();
      this.timeLeftInMS = trialEndDateMS - now;
      this.daysLeft = moment.duration(this.timeLeftInMS).days();
      this.hoursLeft = moment.duration(this.timeLeftInMS).days();
      this.minutesLeft = moment.duration(this.timeLeftInMS).minutes();
   }

   public ngOnDestroy() {
      this.langLoadedSub?.unsubscribe();
      this.trialBannerSub?.unsubscribe();
      if (this.timerInterval) {
         clearInterval(this.timerInterval);
      }

      this.isBasicPlanSub.unsubscribe();
   }

   protected showMessage() {
      const message: string = this.getAlertMessageSnapshot();

      this.alertService.addAlert(message, "warning", Infinity);
   }

   /**
    * On smaller screens on desktop the user can click on the banner icon
    * and see an alert message with a snapshot of the message
    * informing them of their paywall trial status. This method
    * generates that message.
    * @returns The message to be shown in the alert.
    */
   private getAlertMessageSnapshot(): string {
      let message: string = "";

      if (this.lang()) {
         message += this.getDaysLeft();

         message += this.getHoursLeft();

         if (!this.hoursLeft && this.timeLeftInMS > 0) {
            message += this.getMinutesLeft();
         }

         message += this.getEndOfMessage();
      }

      return message;
   }

   private getDaysLeft(): string {
      let message: string = "";
      if (this.daysLeft > 0) {
         message = `${this.daysLeft} ${this.lang()?.Days} `;
      }
      return message;
   }

   private getHoursLeft(): string {
      let message: string = "";
      if (!this.daysLeft && this.hoursLeft > 0) {
         message = `${this.hoursLeft} ${this.lang()?.hours} `;
      }
      return message;
   }

   private getMinutesLeft(): string {
      let message: string = "";
      if (this.minutesLeft < 1) {
         message += `${this.lang()?.LessThan} `;
      }
      if (this.minutesLeft >= 1) {
         message += `${this.minutesLeft} `;
      }
      if (this.minutesLeft > 1) {
         message += `${this.lang()?.Minutes} `;
      }
      if (this.minutesLeft <= 1) {
         message += `${this.lang()?.Minute} `;
      }
      return message;
   }

   private getEndOfMessage(): string {
      let message: string = "";
      if (this.timeLeftInMS > 0) {
         message += `${this.lang()?.LeftInYour} ${this.tierText} ${this.lang()?.Trial}`;
      }

      if (this.timeLeftInMS <= 0) {
         message += `${this.lang()?.Your} ${this.tierText} ${this.lang()?.Trial} ${this.lang()?.IsAboutToExpire}`;
      }
      return message;
   }

   protected openUpgradePage() {
      window.open("https://limblecmms.com/pricing/", "_blank");
   }
}
