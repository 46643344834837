@if (
   locationID !== undefined && showLocation === true && lang() !== undefined && location
) {
   <div [ngClass]="{ 'location-container': !isChild }">
      <div
         class="hasChild"
         [ngClass]="{
            open: unfoldLoc,
            closed: !unfoldLoc,
            inactiveClass: location.inactive,
         }"
      >
         <div
            (click)="toggleLocationActive()"
            class="locationHeader"
            [ngClass]="{ 'highlight-nav': activeRouteName !== undefined }"
         >
            <div
               class="locationTitle"
               [limUiPopover]="startMultipleLocationsPopover"
               [limUiPopoverType]="'upsell'"
               [limUiPopoverPlacement]="'right'"
               [limUiPopoverHidden]="featureMultipleLocations || !location.inactive"
            >
               <div class="title">
                  <lim-ui-icon icon="houseChimney" iconSize="small" />{{
                     location.locationName
                  }}
               </div>
               @if (!location.inactive) {
                  <lim-ui-icon
                     [icon]="unfoldLoc ? 'angleUp' : 'angleDown'"
                     iconSize="small"
                  />
               } @else {
                  <lim-ui-icon icon="stars" iconSize="small" />
               }
               <ng-template #active>
                  <lim-ui-icon icon="stars" iconSize="small" />
               </ng-template>
            </div>
         </div>
         <ng-template #startMultipleLocationsPopover>
            <upsell-popover
               [message]="lang().FeatureMultipleLocationsPopoverMessage"
               [pricingLinkText]="lang().LearnMore"
            />
         </ng-template>
         @if (!location.inactive && unfoldLoc) {
            <div
               class="location dropdown"
               [ngClass]="{
                  'purchasing-unfolded': unfoldPurchasing,
               }"
               style="--purchasing-children: {{ purchasingCredCount }};"
            >
               @if (creds?.tasks) {
                  <div
                     class="hasChild"
                     [ngClass]="{
                        active: activeRouteName === 'locationDashboard',
                     }"
                  >
                     <a
                        [routerLink]="['/locationDashboard', locationID]"
                        class="manageWorkHeader dropDownToggle"
                        [ngClass]="{
                           selected: activeRouteName === 'locationDashboard',
                        }"
                     >
                        <div class="title">
                           <div class="iconWrapper">
                              <lim-ui-icon icon="barsProgress" iconSize="small" />
                           </div>
                           {{ lang().manageWork }}
                        </div>
                     </a>

                     <div
                        class="manageWork"
                        [ngClass]="{
                           'dropDown': true,
                           'parent-active': activeRouteName === 'locationDashboard',
                        }"
                     >
                        @if (showWorkRequestInbox()) {
                           <a
                              [routerLink]="['/workRequestInbox', locationID]"
                              [ngClass]="{
                                 selected: activeRouteName === 'workRequestInbox',
                              }"
                           >
                              <div class="iconWrapper">
                                 <lim-ui-icon icon="clipboard" iconSize="small" />
                              </div>
                              <span class="nav-label title">{{
                                 workRequestInboxLabel
                              }}</span>
                           </a>
                        }
                        <a
                           [routerLink]="['/tasks', locationID]"
                           [ngClass]="{
                              selected: activeRouteName === 'tasks',
                           }"
                        >
                           <div class="iconWrapper">
                              <lim-ui-icon icon="circleExclamation" iconSize="small" />
                           </div>
                           <span class="nav-label title">{{ lang().OpenTasks }}</span>
                        </a>
                        <a
                           [routerLink]="['/completedTasks', locationID]"
                           class="cursor subNav"
                           [ngClass]="{
                              selected:
                                 activeRouteName === 'completedTasks' ||
                                 activeRouteName === 'completedTasksLegacy',
                           }"
                        >
                           <div class="iconWrapper">
                              <lim-ui-icon icon="squareCheck" iconSize="small" />
                           </div>
                           <span class="nav-label title">{{
                              lang().CompletedTasks
                           }}</span>
                        </a>

                        @if (creds?.wr) {
                           <a
                              (click)="reportProblem()"
                              class="loc-problem-link"
                              [ngClass]="{
                                 selected: activeRouteName === 'loc-problem',
                              }"
                           >
                              <div class="iconWrapper">
                                 <lim-ui-icon
                                    icon="triangleExclamation"
                                    iconSize="small"
                                 />
                              </div>
                              <span class="nav-label title">{{
                                 lang().reportAProblem
                              }}</span>
                           </a>
                        }
                     </div>
                  </div>
               }
               @if (!creds?.tasks && creds?.myOpenTasks) {
                  <a
                     [routerLink]="['/tasks', locationID]"
                     [ngClass]="{
                        selected: activeRouteName === 'tasks',
                     }"
                  >
                     <div class="iconWrapper">
                        <lim-ui-icon icon="circleExclamation" iconSize="small" />
                     </div>
                     {{ lang().OpenTasks }}
                  </a>
               }
               @if (!creds?.tasks && (creds?.completedTasks || creds?.myCompletedTasks)) {
                  <a
                     [routerLink]="['/completedTasks', locationID]"
                     [ngClass]="{
                        selected: activeRouteName === 'completedTasks',
                     }"
                  >
                     <div class="iconWrapper">
                        <lim-ui-icon icon="squareCheck" iconSize="small" />
                     </div>
                     {{ lang().CompletedTasks }}
                  </a>
               }
               @if (!creds?.tasks && creds?.newTasks) {
                  <a (click)="startWorkOrder()">
                     <div class="iconWrapper">
                        <lim-ui-icon icon="wpforms" iconSize="small" />
                     </div>
                     {{ lang().startAWorkOrder }}
                  </a>
               }
               @if (!creds?.tasks && creds?.wr) {
                  <a
                     (click)="reportProblem()"
                     [ngClass]="{
                        selected: activeRouteName === 'loc-problem',
                     }"
                  >
                     <div class="iconWrapper">
                        <lim-ui-icon icon="triangleExclamation" iconSize="small" />
                     </div>
                     {{ lang().reportAProblem }}
                  </a>
               }
               @if (!creds?.asset && creds?.lookAsset) {
                  <a (click)="lookUpAsset()">
                     <div class="iconWrapper">
                        <lim-ui-icon icon="cubes" iconSize="small" />
                     </div>
                     {{ lang().lookUpAnAsset }}
                  </a>
               }
               @if (creds?.asset) {
                  <a
                     [routerLink]="['/assetManagement', locationID]"
                     [ngClass]="{
                        selected: activeRouteName === 'assetManagement',
                     }"
                  >
                     <div class="iconWrapper">
                        <lim-ui-icon icon="cubes" iconSize="small" />
                     </div>
                     {{ lang().Assets }}
                  </a>
               }
               @if (creds?.pm) {
                  <a
                     [routerLink]="['/pmManagement', locationID]"
                     [ngClass]="{
                        selected:
                           activeRouteName === 'pmManagement' ||
                           activeRouteName === 'pmManagementLegacy',
                     }"
                  >
                     <div class="iconWrapper">
                        <lim-ui-icon icon="wrench" iconSize="small" />
                     </div>
                     {{ lang().PMs }}
                  </a>
               }

               @if (creds?.part) {
                  @if ((featureUnlimitedParts || featureLimitedNumber) && !isBasicPlan) {
                     <a
                        [routerLink]="['/partsManagement', locationID]"
                        [ngClass]="{
                           selected: activeRouteName === 'partsManagement',
                        }"
                     >
                        <div class="iconWrapper">
                           <lim-ui-icon icon="gears" iconSize="small" />
                        </div>
                        {{ lang().Parts }}
                     </a>
                  } @else {
                     <a
                        [limUiPopover]="startPartsPopover"
                        [limUiPopoverType]="'upsell'"
                        [limUiPopoverPlacement]="'right'"
                        [limUiPopoverHidden]="
                           featureUnlimitedParts || featureLimitedNumber
                        "
                     >
                        <div class="iconWrapper">
                           <lim-ui-icon icon="addressCard" iconSize="small" />
                        </div>

                        {{ lang().Parts }}

                        @if (!featureUnlimitedParts && !featureLimitedNumber) {
                           <lim-ui-badge icon="stars" />
                        }
                        <ng-template #startPartsPopover>
                           <upsell-popover
                              [message]="lang().FeatureUnlimitedPartsPopUpMessage"
                              [pricingLinkText]="lang().LearnMore"
                           />
                        </ng-template>
                     </a>
                  }
               }

               @if (creds?.lookPart) {
                  <a (click)="lookUpPart()">
                     <div class="iconWrapper">
                        <lim-ui-icon icon="gears" iconSize="small" />
                     </div>
                     {{ lang().lookUpAPart }}
                  </a>
               }

               @if (creds?.team) {
                  <a
                     [routerLink]="['/profileManagement', locationID]"
                     [ngClass]="{
                        selected: activeRouteName === 'profileManagement',
                     }"
                  >
                     <div class="iconWrapper">
                        <lim-ui-icon icon="users" iconSize="small" />
                     </div>
                     {{ lang().Teams }}
                  </a>
               }

               @if (creds?.vendor) {
                  @if (
                     (featureUnlimitedVendors || featureLimitedNumber) && !isBasicPlan
                  ) {
                     <a
                        [routerLink]="['/vendorManagement', locationID]"
                        [ngClass]="{
                           selected: activeRouteName === 'vendorManagement',
                        }"
                     >
                        <div class="iconWrapper">
                           <lim-ui-icon icon="addressCard" iconSize="small" />
                        </div>
                        {{ lang().Vendors }}
                     </a>
                  } @else {
                     <a
                        [limUiPopover]="startVendorsPopover"
                        [limUiPopoverType]="'upsell'"
                        [limUiPopoverPlacement]="'right'"
                        [limUiPopoverHidden]="
                           featureUnlimitedVendors || featureLimitedNumber
                        "
                     >
                        <div class="iconWrapper">
                           <lim-ui-icon icon="addressCard" iconSize="small" />
                        </div>

                        {{ lang().Vendors }}

                        @if (!featureUnlimitedVendors && !featureLimitedNumber) {
                           <lim-ui-badge icon="stars" />
                        }
                        <ng-template #startVendorsPopover>
                           <upsell-popover
                              [message]="lang().FeatureUnlimitedVendorsMessage"
                              [pricingLinkText]="lang().LearnMore"
                           />
                        </ng-template>
                     </a>
                  }
               }

               @if (creds?.lookVendor) {
                  <a (click)="lookUpVendor()">
                     <div class="iconWrapper">
                        <lim-ui-icon icon="addressCard" iconSize="small" />
                     </div>
                     {{ lang().lookUpAVendor }}
                  </a>
               }

               @if (creds?.pos || creds?.bills || creds?.budget) {
                  <div
                     class="hasChild"
                     [limUiPopover]="startPOsPopover"
                     [limUiPopoverType]="'upsell'"
                     [limUiPopoverPlacement]="'right'"
                     [limUiPopoverHidden]="
                        featureUnlimitedPOs || featureLimitedNumber || !unfoldPurchasing
                     "
                  >
                     <ng-template #startPOsPopover>
                        <upsell-popover
                           [message]="lang().FeatureUnlimitedPOsMessage"
                           [pricingLinkText]="lang().LearnMore"
                        />
                     </ng-template>

                     @if (featureUnlimitedPOs || featureLimitedNumber) {
                        <a
                           (click)="toggleUnfoldPurchasing()"
                           [routerLink]="['/poManagement', locationID]"
                           class="purchasingHeader dropDownToggle"
                        >
                           <div class="title">
                              <div class="iconWrapper">
                                 <lim-ui-icon icon="dollarSign" iconSize="small" />
                              </div>
                              {{ lang().Purchasing }}
                           </div>
                           <lim-ui-icon
                              [icon]="unfoldPurchasing ? 'angleUp' : 'angleDown'"
                              iconSize="small"
                           />
                        </a>

                        <div
                           class="purchasing"
                           [ngClass]="{ dropDown: unfoldPurchasing }"
                        >
                           @if (creds?.pos) {
                              <a
                                 [routerLink]="['/poManagement', locationID]"
                                 [ngClass]="{
                                    selected: activeRouteName === 'poManagement',
                                 }"
                                 ><div class="iconWrapper">
                                    <lim-ui-icon icon="filePowerpoint" iconSize="small" />
                                 </div>
                                 {{ lang().POs }}
                              </a>
                           }
                           @if (creds?.bills) {
                              <a
                                 [routerLink]="['/prManagement', locationID]"
                                 [ngClass]="{
                                    selected: activeRouteName === 'prManagement',
                                 }"
                              >
                                 <div class="iconWrapper">
                                    <lim-ui-icon icon="fileLines" iconSize="small" />
                                 </div>
                                 {{ lang().Bills }}
                              </a>
                           }

                           @if (creds?.budget) {
                              <a
                                 [routerLink]="['/budgetManagement', locationID]"
                                 [ngClass]="{
                                    selected: activeRouteName === 'budgetManagement',
                                 }"
                              >
                                 <div class="iconWrapper">
                                    <lim-ui-icon icon="creditCard" iconSize="small" />
                                 </div>
                                 {{ lang().Budgets }}
                                 @if (!featureCustomBudgets && featureUnlimitedPOs) {
                                    <lim-ui-badge
                                       class="pricing-plan-badge"
                                       icon="stars"
                                       [limUiPopover]="startCustomBudgetsPopover"
                                       [limUiPopoverType]="'upsell'"
                                       [limUiPopoverPlacement]="'right'"
                                    />
                                 }
                              </a>
                           }
                        </div>
                     } @else {
                        <a
                           (click)="toggleUnfoldPurchasing()"
                           class="purchasingHeader dropDownToggle"
                        >
                           <div class="title">
                              <div class="iconWrapper">
                                 <lim-ui-icon icon="dollarSign" iconSize="small" />
                              </div>
                              {{ lang().Purchasing }}

                              <lim-ui-badge icon="stars" />
                           </div>
                           <lim-ui-icon
                              [icon]="unfoldPurchasing ? 'angleUp' : 'angleDown'"
                              iconSize="small"
                           />
                        </a>

                        <div
                           class="purchasing"
                           [ngClass]="{ dropDown: unfoldPurchasing }"
                        >
                           @if (creds?.pos) {
                              <a
                                 [ngClass]="{
                                    selected: activeRouteName === 'poManagement',
                                 }"
                              >
                                 <div class="iconWrapper">
                                    <lim-ui-icon icon="filePowerpoint" iconSize="small" />
                                 </div>
                                 {{ lang().POs }}
                              </a>
                           }
                           @if (creds?.bills) {
                              <a
                                 [ngClass]="{
                                    selected: activeRouteName === 'prManagement',
                                 }"
                              >
                                 <div class="iconWrapper">
                                    <lim-ui-icon icon="fileLines" iconSize="small" />
                                 </div>
                                 {{ lang().Bills }}
                              </a>
                           }

                           @if (creds?.budget) {
                              <a
                                 [ngClass]="{
                                    selected: activeRouteName === 'budgetManagement',
                                 }"
                              >
                                 <div class="iconWrapper">
                                    <lim-ui-icon icon="creditCard" iconSize="small" />
                                 </div>
                                 {{ lang().Budgets }}
                                 @if (!featureCustomBudgets && featureUnlimitedPOs) {
                                    <lim-ui-badge
                                       class="pricing-plan-badge"
                                       icon="stars"
                                       [limUiPopover]="startCustomBudgetsPopover"
                                       [limUiPopoverType]="'upsell'"
                                       [limUiPopoverPlacement]="'right'"
                                    />
                                 }
                              </a>
                           }
                        </div>
                     }
                  </div>
               }
            </div>
         }
      </div>
   </div>
}

<ng-template #startCustomBudgetsPopover>
   <upsell-popover
      [message]="lang().FeatureCustomBudgetsPopoverMessage"
      [pricingLinkText]="lang().LearnMore"
   />
</ng-template>
