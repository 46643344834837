import { NgClass } from "@angular/common";
import type { OnDestroy, OnInit } from "@angular/core";
import { inject, Component, Input, computed } from "@angular/core";
import {
   IconComponent,
   ModalService,
   LimbleHtmlDirective,
   PaginationComponent,
   PanelComponent,
} from "@limblecmms/lim-ui";
import type { Subscription } from "rxjs";
import { ReplaySubject } from "rxjs";
import { ManageLang } from "src/app/languages/services/manageLang";
import { SortColumn_refactor } from "src/app/shared/components/global/sortColumnModal/sortColumn_refactor.element.component";
import { BetterDate } from "src/app/shared/services/betterDate";
import { ManageFilters } from "src/app/shared/services/manageFilters";
import { ManageObservables } from "src/app/shared/services/manageObservables";
import { ParamsService } from "src/app/shared/services/params.service";
import type { VendorSortFilterColumnInfo } from "src/app/shared/types/general.types";
import type { NotUndefined } from "src/app/shared/types/utility-types";
import { assert } from "src/app/shared/utils/assert.utils";
import { Lookup } from "src/app/shared/utils/lookup";
import { REVERSE } from "src/app/shared/utils/sortingHelpers";
import { ManageUser } from "src/app/users/services/manageUser";
import { PopVendor } from "src/app/vendors/components/popVendorModal/popVendor.modal.component";
import { ManageVendor } from "src/app/vendors/services/manageVendor";
import type { Vendor } from "src/app/vendors/types/vendor.types";

@Component({
   selector: "global-search-vendor-list",
   templateUrl: "./global-search-vendor-list.component.html",
   styleUrls: ["./global-search-vendor-list.component.scss"],
   imports: [
      PanelComponent,
      SortColumn_refactor,
      NgClass,
      IconComponent,
      LimbleHtmlDirective,
      PaginationComponent,
   ],
})
export class GlobalSearchVendorListComponent implements OnInit, OnDestroy {
   @Input() search?: string;
   @Input() vendorIDs?: Array<number>;

   public vendors: Lookup<"vendorID", Vendor> = new Lookup("vendorID");
   public vendorsInView: Lookup<"vendorID", Vendor> = new Lookup("vendorID");
   public page: number = 1;
   public itemsPerPage: number;
   public sortChanges: ReplaySubject<VendorSortFilterColumnInfo> = new ReplaySubject();
   private readonly sortChangesSub: Subscription;
   protected searchHints: Map<number, string> = new Map();
   protected sortColumnInfo: VendorSortFilterColumnInfo;

   private readonly manageObservables = inject(ManageObservables);
   private readonly manageVendor = inject(ManageVendor);
   private readonly modalService = inject(ModalService);
   private readonly paramsService = inject(ParamsService);
   private readonly manageFilters = inject(ManageFilters);
   private readonly betterDate = inject(BetterDate);
   private readonly manageUser = inject(ManageUser);
   private readonly manageLang = inject(ManageLang);

   protected readonly lang = computed(() => this.manageLang.lang() ?? {});

   public constructor() {
      this.itemsPerPage =
         this.manageUser.getCurrentUser()?.userInfo.userUIPreferences.itemsPerPage ?? 10;
      this.sortChangesSub = this.sortChanges.subscribe((sortOptions) => {
         this.orderVendors(sortOptions);
      });
      this.sortColumnInfo = {
         columnName: this.lang().Name,
         sortProperty: "vendorName",
         locationOfProperty: "vendor",
         sortDirection: "ascending",
         entityUniqueID: 0,
         sortChanges: this.sortChanges,
      };
   }

   public ngOnInit(): void {
      if (this.search === undefined) {
         throw new Error("required input `search` is not defined");
      }
      if (this.vendorIDs === undefined) {
         throw new Error("required input `vendorIDs` is not defined");
      }
      this.setVendors();
   }

   public ngOnDestroy(): void {
      this.manageObservables.removeManySubscriptions([this.sortChangesSub]);
   }

   private orderVendors(sortInfo: VendorSortFilterColumnInfo): void {
      if (!sortInfo) {
         return;
      }
      const sortKey = sortInfo.sortProperty;
      if (sortInfo.sortDirection === "ascending") {
         this.vendors = this.vendors.orderBy(sortKey);
      } else {
         this.vendors = this.vendors.orderBy(sortKey, REVERSE);
      }
      this.setVendorsInView();
   }

   protected openVendor(vendor: Vendor): void {
      const instance = this.modalService.open(PopVendor);
      this.paramsService.params = {
         modalInstance: instance,
         resolve: {
            vendorID: vendor.vendorID,
            locationID: vendor.locationID,
            data: {
               restrict: false,
            },
         },
      };
   }

   private setVendors(): void {
      assert(this.vendorIDs !== undefined);
      const vendorsArray = this.vendorIDs
         .map((vendorID) => this.manageVendor.getVendor(vendorID))
         .filter((vendor): vendor is NotUndefined<typeof vendor> => vendor !== undefined);
      this.vendors = new Lookup("vendorID", vendorsArray);
      this.setVendorsInView();
   }

   private setVendorsInView() {
      this.vendorsInView = this.vendors.slice(
         (this.page - 1) * this.itemsPerPage,
         this.page * this.itemsPerPage,
      );
      this.addSearchHintsForVendors();
   }

   protected addSearchHintsForVendors() {
      this.manageFilters.filterVendorsToNameAndTextFields(
         this.vendorsInView,
         this.manageVendor.getFields(),
         this.manageVendor.getValues(),
         this.manageVendor.getFiles(),
         this.searchHints,
         {
            search: this.search,
            hier: false,
            field: false,
         },
         this.betterDate,
      );
   }

   protected pageChange(newPage: number): void {
      this.page = newPage;
      this.setVendorsInView();
   }
}
